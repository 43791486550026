

























import { computed, defineComponent, provide } from "@vue/composition-api";
import { IUserState } from "@/lib/types/user";
import { IOrganizationDetails } from "@/lib/types/organization";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "Settings",
  components: {
    AccountSettings: () => import("./AccountSettings.vue"),
    OrganizationSettings: () => import("./OrganizationSettings.vue"),
  },
  data () {
    return {
      key: 0,
    };
  },
  computed: {
    user (): IUserState {
      return this.$store.state.user;
    },
    organization (): IOrganizationDetails {
      const organizationType = this.user.details.organization_type;

      return this.$store.state[organizationType.slice(0, 1).toLowerCase() + organizationType.slice(1)];
    },
  },
  mounted () {
    // this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === "user/SET_USER_DETAILS") this.key += 1;
    // });
  },
  setup () {
    const { root } = rootInstance();
    const $state = root.$store.state;

    root.$store.dispatch("user/setUserDetails");

    // ! Organization type must be converted to a camel case string
    const organizationRef = computed(() => {
      const type = $state.user.details.organization_type;
      return root.$store.state[type.slice(0, 1).toLowerCase() + type.slice(1)];
    });

    provide("organization", organizationRef);
  },
});
